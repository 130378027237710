#services {
  padding: 0px 16px;
  text-align: center;
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    padding: 0px 30px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0px 92px;
    margin-top: 100px;
  }
}

.services {
  .section-heading {
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;

      br {
        display: none;
      }
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 2px;

      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 3px;
      }
    }

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 36px;

      @media screen and (min-width: 768px) {
        font-size: 36px;
        line-height: 54px;
      }

      span {
        color: rgba(70, 62, 255, 1);
      }
    }
  }

  h3 {
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;

    @media screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 54px;
      text-align: left;
    }
  }
}

.service-list {
  margin-bottom: 60px;

  .owl-item {
    padding: 20px 30px 0px 30px;
  }

  .owl-carousel .owl-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    column-gap: 12px;

    @media screen and (min-width: 1024px) {
      margin-top: 60px;
    }
  }

  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #463eff;
    color: #ffffff;

    span {
      font-size: 30px;
    }

    &.disabled {
      border: 1px solid rgba(0, 0, 0, 0.4);
      background: transparent;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.service-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;

  @media screen and (min-width: 1024px) {
    row-gap: 32px;
  }

  .pink {
    background: #f7e0ff;
  }

  .blue {
    background: #b9ebf7;
  }

  .orange {
    background: #ffefca;
  }

  div {
    width: 86px;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @media screen and (min-width: 1024px) {
      height: 121px;
      width: 121px;
    }

    &:hover {
      img {
        transition: 0.5s linear;
        transform: translateY(0px);
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      transition: 0.5s linear;
      transform: translateY(-20px);
    }
  }

  h4 {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 24px;

    @media screen and (min-width: 1024px) {
      font-size: 24px;
      line-height: 36px;
    }
  }
}
