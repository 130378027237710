#values {
  padding: 0px 16px;
  text-align: center;
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    padding: 0px 30px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0px 92px;
  }
}

.values {
  .section-heading {
    margin-bottom: 80px;

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 36px;

      @media screen and (min-width: 768px) {
        font-size: 36px;
        line-height: 54px;
      }

      span {
        color: rgba(70, 62, 255, 1);
      }
    }
  }
}

.values-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 78px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    column-gap: 20px;
  }
}

.value-item {
  padding: 0px 25px 50px 25px;
  border-radius: 10px;
  position: relative;

  &:nth-of-type(1) {
    border: 2px solid #fd49fd;

    div div {
      background: #ffe7f4;
    }
  }

  &:nth-of-type(2) {
    border: 2px solid #499cfd;

    div div {
      background: #d5e8ff;
    }
  }

  &:nth-of-type(3) {
    border: 2px solid #f5b522;

    div div {
      background: #fff5dc;
    }
  }

  & > div {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    margin-bottom: 38px;
    margin-top: -45px;

    div {
      width: 98px;
      height: 98px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h4 {
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 14.5px;
  }

  p {
    font-size: 12px;
    line-height: 18px;
  }
}
