header {
  padding: 9px 16px;
  position: relative;

  @media screen and (min-width: 768px) {
    padding: 0px 30px;
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0px 92px;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 768px) {
      padding: 22px 0px;
    }

    .logo-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .logo {
      height: 18px;
      width: 30px;

      @media screen and (min-width: 768px) {
        height: 36px;
        width: 60px;
      }
    }
    .it-name {
      font-size: 16px;
      font-weight: 700;
      background: linear-gradient(to right, #db699d, #5292ca);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 'Gilroy-Bold';
      margin-left: 10;
      @media screen and (min-width: 768px) {
        font-size: 26px;
      }
    }

    .menu {
      height: 32px;
      width: 32px;
      border: none;
      background-color: transparent;

      @media screen and (min-width: 768px) {
        display: none;
      }

      &.active {
        .open-menu {
          display: none;
        }

        .close-menu {
          display: block;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .close-menu {
        display: none;
      }
    }
  }
}

nav {
  width: 100%;
  height: 100vh;
  position: absolute;
  position: absolute;
  top: 50px;
  left: 0;
  background-color: #f7f7f7;
  border-top: 1px solid #b9b9b9ad;
  transform: translateX(300%);
  transition: 1s;

  @media screen and (min-width: 768px) {
    transform: translateX(0px);
    background-color: transparent;
    border: none;
    position: static;
    height: 100%;
  }

  &.active {
    transform: translateX(0);
    transition: 1s;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: flex-end;
      height: 100%;
      column-gap: 12px;
    }

    li {
      width: 100%;

      @media screen and (min-width: 768px) {
        height: 100%;
      }

      @media screen and (min-width: 1024px) {
        width: max-content;
      }

      a {
        display: block;
        padding: 16px 0px;
        width: 100%;
        text-align: center;
        font-family: 'Gilroy-Medium';
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        @media screen and (min-width: 768px) {
          height: 100%;
          padding: 28px 0px;
        }

        @media screen and (min-width: 1024px) {
          width: max-content;
          padding: 28px 22px;
        }
      }

      &:hover {
        background-color: #ffffff;
      }
    }
  }
}
