#client {
  padding: 0px 16px;
  text-align: center;
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    padding: 0px 30px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0px 92px;
    margin-top: 100px;
  }
}

.client {
  background-image: url(../images/bg2.png);
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;

  .section-heading {
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
      margin-bottom: 50px;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 2px;

      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 3px;
      }
    }

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 36px;

      @media screen and (min-width: 768px) {
        font-size: 36px;
        line-height: 54px;
      }

      span {
        color: rgba(70, 62, 255, 1);
      }
    }
  }
}

.clients-mobile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px 46px;

  @media screen and (min-width: 768px) {
    display: none;
    // grid-template-columns: repeat(5, 16%);
    // gap: 60px 46px;
  }

  // @media screen and (min-width: 1024px) {
  //   gap: 0;

  //   .client-item {
  //     width: 100%;
  //     height: 150px;
  //     padding: 0px;
  //     border: 1px solid #dbdbdb;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     &:nth-of-type(1) {
  //       border-left: none;
  //       border-top: none;
  //     }

  //     &:nth-of-type(2),
  //     &:nth-of-type(3),
  //     &:nth-of-type(4) {
  //       border-top: none;
  //     }

  //     &:nth-of-type(5) {
  //       border-right: none;
  //       border-top: none;
  //     }

  //     &:nth-of-type(6) {
  //       border-left: none;
  //       border-bottom: none;
  //     }

  //     &:nth-of-type(7),
  //     &:nth-of-type(8),
  //     &:nth-of-type(9) {
  //       border-bottom: none;
  //     }

  //     &:nth-of-type(10) {
  //       border-right: none;
  //       border-bottom: none;
  //     }

  //     img {
  //       width: 70px;
  //     }
  //   }
  // }

  // @media screen and (min-width: 1300px) {
  //   .client-item {
  //     height: 200px;
  //     padding: 46px 60px;
  //   }
  // }
}

.clients-desktop {
  display: none;

  @media screen and (min-width: 768px) {
    // background-image: url(../images/clients/grid-bg.png);
    // background-size: 100%;
    // background-position: center;
    // background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 106px;
  }

  img {
    width: 100%;
    height: auto;
  }
}
