footer {
  background-color: #000000;
  color: #ffffff;
  text-align: center;
}

.footer-nav {
  padding: 35px;

  @media screen and (min-width: 1024px) {
    padding: 106px 92px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
  }

  & > div {
    margin-bottom: 32px;

    @media screen and (min-width: 1024px) {
      width: 25%;
    }
  }

  h2 {
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  p,
  a,
  address {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #b7b7b7;
  }

  li {
    margin-bottom: 12px;
  }

  & > div > div {
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 11px;

    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: 10% 70%;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 21px;
    }

    &.socials-list {
      display: none;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      column-gap: 19px;

      @media screen and (min-width: 1024px) {
        display: flex;
      }
    }
  }

  .socials {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 19px;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
}

.copyright {
  background: #242323;
  padding: 9px 0px;
  text-align: center;
  font-family: 'Gilroy-Medium';
  font-size: 8px;
  line-height: 12px;
  color: #b7b7b7;

  @media screen and (min-width: 768px) {
    padding: 18px 0px;
    font-size: 16px;
    line-height: 24px;
  }
}
