@font-face {
  font-family: 'Gilroy';
  src: url(../fonts/Gilroy-Regular.woff), url(../fonts/Gilroy-Regular.eot),
    url(../fonts/Gilroy-Regular.ttf);
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url(../fonts/Gilroy-Medium.woff), url(../fonts/Gilroy-Medium.eot),
    url(../fonts/Gilroy-Medium.ttf);
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url(../fonts/Gilroy-Bold.woff), url(../fonts/Gilroy-Bold.eot),
    url(../fonts/Gilroy-Bold.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Gilroy';
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

// img {
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
// }

@import 'header';
@import 'hero';
@import 'product';
@import 'process';
@import 'client';
@import 'services';
@import 'values';
@import 'testimonials';
@import 'consult';
@import 'footer';
