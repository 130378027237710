#product {
  padding: 0px 16px;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 60px;

  @media screen and (min-width: 768px) {
    padding: 0px 30px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0px 92px;
    margin-top: 100px;
  }
}

.product {
  .section-heading {
    @media screen and (min-width: 1024px) {
      background-image: url(../images/product/bg-desktop.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
    }

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 36px;
      padding: 0px 16px;
      margin-bottom: 24px;

      @media screen and (min-width: 768px) {
        font-size: 36px;
        line-height: 54px;
        margin-bottom: 58px;
      }

      span {
        color: rgba(70, 62, 255, 1);
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      @media screen and (min-width: 768px) {
        justify-content: center;
        column-gap: 60px;
      }
    }

    .product-feature {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 16px;

      @media screen and (min-width: 1024px) {
        flex-direction: row;
        column-gap: 25px;
        height: 66px;
        width: max-content;
        border-radius: 30px;
        background: #e8eaff;
        padding: 0px 20px;

        &:hover {
          div {
            transform: translateY(0px);
            transition: 0.5s;
          }
        }
      }

      div {
        width: 53px;
        height: 53px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 50%;
        filter: drop-shadow(0px 3px 13px rgba(0, 0, 0, 0.078));

        img {
          width: 100%;
          height: 30px;
          object-fit: contain;
        }

        @media screen and (min-width: 1024px) {
          height: 77px;
          width: 77px;
          transform: translateY(-30px);
          transition: 0.5s;

          img {
            height: 40px;
          }
        }
      }

      p {
        height: 40px;
        width: 98px;
        border-radius: 50px;
        background: #e8eaff;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 1024px) {
          background-color: transparent;
          width: auto;
          height: auto;
          border-radius: 0%;
          display: block;
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }
}

.mobile-dashboard {
  border: 1px solid #463eff;
  border-radius: 24px;
  padding: 10px 16px;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  .dashboard-header {
    .actions {
      display: flex;
      justify-content: end;
      align-items: center;
      column-gap: 30px;
      margin-bottom: 26px;

      .action {
        width: 31px;
        height: 31px;
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.078));
      }
    }

    h3 {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 24px;
    }
  }

  .items-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px 16px;
    margin-bottom: 24px;

    .item {
      width: 47%;
      height: 186px;
      padding: 14px 6px;
      border-radius: 10px;
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: column;
      row-gap: 10px;

      &:nth-of-type(1) {
        background-color: rgba(196, 223, 255, 1);
      }

      &:nth-of-type(2) {
        background-color: rgba(225, 246, 241, 1);
      }

      &:nth-of-type(3) {
        background-color: rgba(255, 239, 202, 1);
      }

      &:nth-of-type(4) {
        background-color: rgba(255, 210, 232, 1);
      }

      @media screen and (min-width: 378px) {
        height: auto;
      }

      div {
        height: 76px;
        width: 76px;
        border-radius: 50%;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h4 {
        font-family: 'Gilroy-Medium';
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .pie-chart {
    width: 100%;
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 22px;
    padding: 22px;

    h3 {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
}

.desktop-dashboard {
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    border: 1px solid rgba(70, 62, 255, 1);
    border-radius: 20px;
    filter: drop-shadow(3.786px 2px 76px rgba(0, 0, 0, 0.078));
    background: #f4f5ff;
    padding: 24px;
  }
}

.container {
  background: #ffffff;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 25% 75%;

  .col1 {
    background: #fdfff4;
    border-radius: 15px 0px 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      width: 100%;
      padding: 10px 26px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 17px;

      &.active {
        background: #79d7db;
        color: #ffffff;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 27px;
      }

      p {
        &:empty {
          height: 12px;
          background: #dfdfdf;
          border-radius: 5px;

          &:nth-of-type(1) {
            width: 75px;
          }

          &:nth-of-type(2) {
            width: 65px;
          }

          &:nth-of-type(3) {
            width: 70px;
          }

          &:nth-of-type(4) {
            width: 40px;
          }

          &:nth-of-type(5) {
            width: 80px;
          }
        }
      }
    }
  }

  .col2 {
    padding: 41px 30px;
    width: 100%;
  }

  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    h3 {
      font-family: 'Gilroy-Bold';
      font-size: 23px;
      line-height: 34px;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 36px;

      div {
        background-color: #ffffff;
        filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.078));
        border-radius: 15px;
        height: 38px;
        width: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .cards-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;
    margin-bottom: 37px;

    .card-product {
      height: 180px;
      width: 174px;
      border-radius: 20px;
      background: #79d7db;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      row-gap: 30px;
      padding: 10px;
      padding-top: 24px;

      &:nth-of-type(1) {
        background: #c4dfff;
      }

      &:nth-of-type(2) {
        background: #e1f6f1;
      }

      &:nth-of-type(3) {
        background: #ffefca;
      }

      &:nth-of-type(4) {
        background: #ffd2e8;
      }

      div {
        height: 51px;
        width: 51px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;

        img {
          width: 26px;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }

  .statistics {
    @media screen and (min-width: 1300px) {
      display: flex;
      column-gap: 37px;
    }
  }

  .barchart {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 20px 25px;
    text-align: left;
    margin-bottom: 37px;

    @media screen and (min-width: 1300px) {
      width: 70%;
      margin-bottom: 0px;
      height: 100%;
    }

    img {
      width: 100%;
    }
  }

  .piechart {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 20px 25px;

    @media screen and (min-width: 1300px) {
      width: 30%;
    }

    h3 {
      margin-bottom: 40px;
    }
  }
}
