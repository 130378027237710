#testimonials {
  text-align: center;

  @media screen and (min-width: 1024px) {
    padding: 90px 0px;
  }
}

.testimonials {
  .section-heading {
    margin-bottom: 21px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 2px;

      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 3px;
      }
    }

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 36px;

      @media screen and (min-width: 768px) {
        font-size: 36px;
        line-height: 54px;
      }

      span {
        color: rgba(70, 62, 255, 1);
      }
    }
  }

  .marquee-container {
    height: 200px;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    gap: 30px;
  }

  .marquee {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .marquee-list {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.testimonials-item {
  width: 300px;
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 17px;

  &.orange {
    background: #fffbeb;
  }

  &.pink {
    background: #fff4fa;
  }

  &.green {
    background: #effffb;
  }

  p {
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 63px;

    img {
      vertical-align: top;
      width: 7px;
      height: 7px;
    }
  }

  .user {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: left;

    .avatar {
      width: 33px;
      height: 33px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .details {
      font-size: 12px;
      line-height: 18px;

      .name {
        font-family: 'Gilroy-Bold';
      }

      .role {
        font-weight: 400;
      }
    }

    .rating {
      img {
        width: 11px;
        height: 11px;
      }
    }
  }
}
