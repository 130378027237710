#process {
  padding: 0px 16px;
  text-align: center;
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    padding: 0px 30px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0px 92px;
    margin-top: 100px;
  }
}

.process {
  .section-heading {
    margin-bottom: 45px;

    @media screen and (min-width: 768px) {
      margin-bottom: 88px;
    }

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 36px;
      padding: 0px 16px;

      @media screen and (min-width: 768px) {
        font-size: 36px;
        line-height: 54px;
      }

      span {
        color: rgba(70, 62, 255, 1);
      }
    }
  }

  .items-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 34px 0px;
    margin-bottom: 40px;

    @media screen and (min-width: 768px) {
      row-gap: 60px;
      margin-bottom: 90px;
      background-image: url(../images/bg2.png);
      background-size: 80%;
      background-position: right;
      background-repeat: no-repeat;
    }

    @media screen and (min-width: 768px) {
      flex-wrap: wrap;
      column-gap: 300px;
      row-gap: 76px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 9px;
    width: 50%;

    @media screen and (min-width: 768px) {
      row-gap: 28px;
    }

    @media screen and (min-width: 768px) {
      width: max-content;
    }

    &:nth-of-type(1) {
      div {
        background-color: rgba(247, 224, 255, 1);
      }
    }

    &:nth-of-type(2) {
      div {
        background-color: rgba(185, 235, 247, 1);
      }
    }

    &:nth-of-type(3) {
      div {
        background-color: rgba(255, 239, 202, 1);
      }
    }

    &:nth-of-type(4) {
      div {
        background-color: rgba(221, 250, 212, 1);
      }
    }

    &:nth-of-type(5) {
      div {
        background-color: rgba(255, 212, 206, 1);
      }
    }

    div {
      height: 86px;
      width: 86px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      @media screen and (min-width: 1024px) {
        height: 121px;
        width: 121px;
      }

      img {
        transform: translateY(-30px);
        transition: 0.5s linear;
        width: 71px;

        @media screen and (min-width: 1024px) {
          width: 120px;
        }

        &:hover {
          transform: translateY(0px);
          transition: 0.5s linear;
        }
      }
    }

    h3 {
      font-family: 'Gilroy-Medium';
      font-size: 16px;
      line-height: 24px;

      @media screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}

.meeting {
  padding: 23px;
  border: 1px solid rgba(70, 62, 255, 1);
  border-radius: 24px;
  background-color: #ffffff;
  filter: drop-shadow(3.786px 2px 76px rgba(0, 0, 0, 0.078));

  @media screen and (min-width: 600px) {
    width: 70%;
    margin: auto;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 48% 1% 48%;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
  }
}

.meeting-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  @media screen and (min-width: 600px) {
    width: max-content;
    margin: 0px auto 32px auto;
  }

  @media screen and (min-width: 768px) {
    margin: auto;
    width: 90%;
    padding: 32px;
  }

  h2 {
    font-family: 'Gilroy-Bold';
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 37px;

    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }

  .field {
    text-align: left;
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
      width: 100%;
    }

    label {
      display: block;
      width: 100%;
      margin-bottom: 12.75px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      @media screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    input,
    textarea {
      display: block;
      width: 100%;
      border: 1px solid #000000;
      border-radius: 12px;
      padding: 10px 20px;
      font-family: 'Gilroy';

      &:active,
      &:focus {
        outline-color: #443dfb;
      }

      @media screen and (min-width: 768px) {
        font-size: 18px;
      }
    }
  }

  a,
  button {
    border: none;
    height: 40px;
    width: 180px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #443dfb;
    color: #ffffff;
    border-radius: 100px;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 21px;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 30px;
      padding: 19px 44px;
      height: 64px;
      width: 249px;
    }
  }

  h3 {
    margin: 20px 0px;
  }

  a {
    width: max-content;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #443dfb;
  }
}

.calendly-inline-widget {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.mobile-calendly {
  text-align: center;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.book-meeting-link {
  height: 40px;
  width: 220px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #443dfb;
  color: #443dfb;
  border-radius: 100px;
  font-family: 'Gilroy-Bold';
  font-size: 14px;
  line-height: 21px;
  margin: 23px auto 0px auto;
}

.border {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    width: 2px;
    height: 100%;
    border-right: 1px solid rgba(70, 62, 255, 1);
  }
}
