#consult {
  padding: 40px;
  text-align: center;
  background: #463eff;
  color: #ffffff;

  @media screen and (min-width: 768px) {
    padding: 60px;
  }

  @media screen and (min-width: 768px) {
    padding: 92px;
  }
}

.consult {
  .section-heading {
    margin-bottom: 40px;

    @media screen and (min-width: 768px) {
      margin-bottom: 60px;
    }

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 36px;

      @media screen and (min-width: 768px) {
        font-size: 36px;
        line-height: 54px;
      }
    }
  }

  .meeting-btn {
    display: flex;
    justify-content: center;

    a {
      height: 64px;
      width: 249px;
      border-radius: 50px;
      background-color: #ffffff;
      font-family: 'Gilroy-Bold';
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 30px;
        padding: 19px 44px;
        height: 64px;
        width: 249px;
      }
    }
  }
}

.consult-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 46px;
  row-gap: 40px;
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    column-gap: 100px;
    margin-bottom: 40px;
  }

  @media screen and (min-width: 768px) {
    column-gap: 200px;
    margin-bottom: 60px;
  }
}

.consult-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;

  &:nth-of-type(1) {
    div {
      background-color: rgba(247, 224, 255, 1);
    }
  }

  &:nth-of-type(2) {
    div {
      background-color: rgba(185, 235, 247, 1);
    }
  }

  &:nth-of-type(3) {
    div {
      background-color: rgba(255, 239, 202, 1);
    }
  }

  div {
    width: 86px;
    height: 86px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1024px) {
      height: 121px;
      width: 121px;
    }

    &:hover {
      img {
        transform: translateY(0px);
        transition: 0.5s linear;
      }
    }

    img {
      width: auto;
      height: 77px;
      object-fit: contain;
      transform: translateY(-20px);
      transition: 0.5s linear;

      @media screen and (min-width: 1024px) {
        height: 100px;
      }
    }
  }

  h3 {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 24px;

    @media screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 36px;
    }
  }
}
