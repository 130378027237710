#hero {
  height: 100vh;
  max-height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1024px) {
    display: block;
    height: max-content;
  }
}

.mobile {
  background-image: url('../images/hero/bg.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  .row-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: 'Gilroy-Bold';
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 12px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 32px;
    }
  }

  .row-2 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 48px;
    padding: 16px;

    @media screen and (min-width: 650px) {
      justify-content: space-between;
    }

    img {
      &:nth-of-type(1) {
        width: 60%;
        height: auto;
        animation: move1-mobile 4s linear infinite alternate;

        &:hover {
          animation-play-state: paused;
        }

        @media screen and (min-width: 650px) {
          width: auto;
          height: 312px;
        }
      }

      &:nth-of-type(2) {
        width: 30%;
        height: auto;
        animation: move2-mobile 6s 1s linear infinite alternate;

        &:hover {
          animation-play-state: paused;
        }

        @media screen and (min-width: 650px) {
          width: auto;
          height: 167px;
        }
      }
    }
  }

  .row-3 {
    text-align: center;

    button {
      height: 55px;
      width: 195px;
      background: #463eff;
      border: none;
      padding: 15px 32px;
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      color: #ffffff;
      border-radius: 32px;
    }
  }
}

.desktop {
  display: none;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px 92px;
    background-image: url(../images/bg.png);
    background-repeat: no-repeat;
    background-position: center;
    column-gap: 30px;
  }

  @media screen and (min-width: 1300px) {
    align-items: flex-end;
  }

  .col-1 {
    img {
      height: 231px;
      width: 236px;
      animation: move1-desktop linear 6s infinite alternate;

      &:hover {
        animation-play-state: paused;
      }
    }

    @media screen and (min-width: 1300px) {
      img {
        height: 369px;
        width: 424px;
      }
    }
  }

  .col-2 {
    img {
      height: 274px;
      width: 280px;
    }

    h1 {
      font-family: 'Gilroy-Bold';
      font-size: 45px;
      line-height: 68px;
      margin-bottom: 8px;
    }

    p {
      font-weight: 400;
      font-size: 25px;
      line-height: 45px;
      margin-bottom: 44px;
    }

    button {
      height: 56px;
      width: 199px;
      border: none;
      border-radius: 30px;
      background: #463eff;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
    }
  }

  .col-3 {
    img {
      height: 166px;
      width: 178px;
      animation: move2-desktop linear 8s infinite alternate;

      &:hover {
        animation-play-state: paused;
      }
    }

    @media screen and (min-width: 1300px) {
      img {
        height: 166px;
        width: 178px;
      }
    }
  }
}

@keyframes move1-mobile {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(50px);
  }
}

@keyframes move2-mobile {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(130px);
  }
}

@keyframes move1-desktop {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(80px);
  }
}

@keyframes move2-desktop {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(-300px);
  }
}
