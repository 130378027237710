@font-face {
  font-family: Gilroy;
  src: url("Gilroy-Regular.1eee673e.woff"), url("Gilroy-Regular.0559e6a3.eot"), url("Gilroy-Regular.7553081e.ttf");
}

@font-face {
  font-family: Gilroy-Medium;
  src: url("Gilroy-Medium.a1e825f3.woff"), url("Gilroy-Medium.b3092678.eot"), url("Gilroy-Medium.caaf85ff.ttf");
}

@font-face {
  font-family: Gilroy-Bold;
  src: url("Gilroy-Bold.e9ed37bc.woff"), url("Gilroy-Bold.73d1b424.eot"), url("Gilroy-Bold.cd539b2c.ttf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Gilroy;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

header {
  padding: 9px 16px;
  position: relative;
}

@media screen and (min-width: 768px) {
  header {
    background-color: #f7f7f7;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    padding: 0 30px;
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  header {
    padding: 0 92px;
  }
}

header div {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  header div {
    padding: 22px 0;
  }
}

header div .logo-section {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

header div .logo {
  height: 18px;
  width: 30px;
}

@media screen and (min-width: 768px) {
  header div .logo {
    height: 36px;
    width: 60px;
  }
}

header div .it-name {
  background: linear-gradient(to right, #db699d, #5292ca);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 10px;
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  header div .it-name {
    font-size: 26px;
  }
}

header div .menu {
  height: 32px;
  width: 32px;
  background-color: #0000;
  border: none;
}

@media screen and (min-width: 768px) {
  header div .menu {
    display: none;
  }
}

header div .menu.active .open-menu {
  display: none;
}

header div .menu.active .close-menu {
  display: block;
}

header div .menu img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

header div .menu .close-menu {
  display: none;
}

nav {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  border-top: 1px solid #b9b9b9ad;
  transition: all 1s;
  position: absolute;
  top: 50px;
  left: 0;
  transform: translateX(300%);
}

@media screen and (min-width: 768px) {
  nav {
    height: 100%;
    background-color: #0000;
    border: none;
    position: static;
    transform: translateX(0);
  }
}

nav.active {
  transition: all 1s;
  transform: translateX(0);
}

nav ul {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  display: flex;
}

@media screen and (min-width: 768px) {
  nav ul {
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 12px;
  }
}

nav ul li {
  width: 100%;
}

@media screen and (min-width: 768px) {
  nav ul li {
    height: 100%;
  }
}

@media screen and (min-width: 1024px) {
  nav ul li {
    width: max-content;
  }
}

nav ul li a {
  width: 100%;
  text-align: center;
  color: #000;
  padding: 16px 0;
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 24px;
  display: block;
}

@media screen and (min-width: 768px) {
  nav ul li a {
    height: 100%;
    padding: 28px 0;
  }
}

@media screen and (min-width: 1024px) {
  nav ul li a {
    width: max-content;
    padding: 28px 22px;
  }
}

nav ul li:hover {
  background-color: #fff;
}

#hero {
  height: 100vh;
  max-height: max-content;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1024px) {
  #hero {
    height: max-content;
    display: block;
  }
}

.mobile {
  width: 100%;
  background-image: url("bg.07919953.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media screen and (min-width: 1024px) {
  .mobile {
    display: none;
  }
}

.mobile .row-1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mobile .row-1 h1 {
  margin-bottom: 12px;
  font-family: Gilroy-Bold;
  font-size: 28px;
  line-height: 42px;
}

.mobile .row-1 p {
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.mobile .row-2 {
  align-items: flex-start;
  margin-bottom: 48px;
  padding: 16px;
  display: flex;
}

@media screen and (min-width: 650px) {
  .mobile .row-2 {
    justify-content: space-between;
  }
}

.mobile .row-2 img:nth-of-type(1) {
  width: 60%;
  height: auto;
  animation: move1-mobile 4s linear infinite alternate;
}

.mobile .row-2 img:nth-of-type(1):hover {
  animation-play-state: paused;
}

@media screen and (min-width: 650px) {
  .mobile .row-2 img:nth-of-type(1) {
    width: auto;
    height: 312px;
  }
}

.mobile .row-2 img:nth-of-type(2) {
  width: 30%;
  height: auto;
  animation: move2-mobile 6s linear 1s infinite alternate;
}

.mobile .row-2 img:nth-of-type(2):hover {
  animation-play-state: paused;
}

@media screen and (min-width: 650px) {
  .mobile .row-2 img:nth-of-type(2) {
    width: auto;
    height: 167px;
  }
}

.mobile .row-3 {
  text-align: center;
}

.mobile .row-3 button {
  height: 55px;
  width: 195px;
  color: #fff;
  background: #463eff;
  border: none;
  border-radius: 32px;
  padding: 15px 32px;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
}

.desktop {
  display: none;
}

@media screen and (min-width: 1024px) {
  .desktop {
    text-align: center;
    background-image: url("bg.5c50749f.png");
    background-position: center;
    background-repeat: no-repeat;
    grid-template-columns: 25% 50% 25%;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    padding: 50px 92px;
    display: grid;
  }
}

@media screen and (min-width: 1300px) {
  .desktop {
    align-items: flex-end;
  }
}

.desktop .col-1 img {
  height: 231px;
  width: 236px;
  animation: move1-desktop 6s linear infinite alternate;
}

.desktop .col-1 img:hover {
  animation-play-state: paused;
}

@media screen and (min-width: 1300px) {
  .desktop .col-1 img {
    height: 369px;
    width: 424px;
  }
}

.desktop .col-2 img {
  height: 274px;
  width: 280px;
}

.desktop .col-2 h1 {
  margin-bottom: 8px;
  font-family: Gilroy-Bold;
  font-size: 45px;
  line-height: 68px;
}

.desktop .col-2 p {
  margin-bottom: 44px;
  font-size: 25px;
  font-weight: 400;
  line-height: 45px;
}

.desktop .col-2 button {
  height: 56px;
  width: 199px;
  color: #fff;
  background: #463eff;
  border: none;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  display: flex;
}

.desktop .col-3 img {
  height: 166px;
  width: 178px;
  animation: move2-desktop 8s linear infinite alternate;
}

.desktop .col-3 img:hover {
  animation-play-state: paused;
}

@media screen and (min-width: 1300px) {
  .desktop .col-3 img {
    height: 166px;
    width: 178px;
  }
}

@keyframes move1-mobile {
  from {
    transform: translateY(-30px);
  }

  to {
    transform: translateY(50px);
  }
}

@keyframes move2-mobile {
  from {
    transform: translateY(-30px);
  }

  to {
    transform: translateY(130px);
  }
}

@keyframes move1-desktop {
  from {
    transform: translateY(-100px);
  }

  to {
    transform: translateY(80px);
  }
}

@keyframes move2-desktop {
  from {
    transform: translateY(100px);
  }

  to {
    transform: translateY(-300px);
  }
}

#product {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  #product {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1024px) {
  #product {
    margin-top: 100px;
    padding: 0 92px;
  }

  .product .section-heading {
    background-image: url("bg-desktop.66dc7dfc.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.product .section-heading h2 {
  margin-bottom: 24px;
  padding: 0 16px;
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .product .section-heading h2 {
    margin-bottom: 58px;
    font-size: 36px;
    line-height: 54px;
  }
}

.product .section-heading h2 span {
  color: #463eff;
}

.product .section-heading ul {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .product .section-heading ul {
    justify-content: center;
    column-gap: 60px;
  }
}

.product .section-heading .product-feature {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .product .section-heading .product-feature {
    height: 66px;
    width: max-content;
    background: #e8eaff;
    border-radius: 30px;
    flex-direction: row;
    column-gap: 25px;
    padding: 0 20px;
  }

  .product .section-heading .product-feature:hover div {
    transition: all .5s;
    transform: translateY(0);
  }
}

.product .section-heading .product-feature div {
  width: 53px;
  height: 53px;
  filter: drop-shadow(0 3px 13px #00000014);
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.product .section-heading .product-feature div img {
  width: 100%;
  height: 30px;
  object-fit: contain;
}

@media screen and (min-width: 1024px) {
  .product .section-heading .product-feature div {
    height: 77px;
    width: 77px;
    transition: all .5s;
    transform: translateY(-30px);
  }

  .product .section-heading .product-feature div img {
    height: 40px;
  }
}

.product .section-heading .product-feature p {
  height: 40px;
  width: 98px;
  background: #e8eaff;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .product .section-heading .product-feature p {
    width: auto;
    height: auto;
    background-color: #0000;
    border-radius: 0%;
    font-size: 24px;
    line-height: 36px;
    display: block;
  }
}

.mobile-dashboard {
  border: 1px solid #463eff;
  border-radius: 24px;
  padding: 10px 16px;
}

@media screen and (min-width: 1024px) {
  .mobile-dashboard {
    display: none;
  }
}

.mobile-dashboard .dashboard-header .actions {
  justify-content: end;
  align-items: center;
  column-gap: 30px;
  margin-bottom: 26px;
  display: flex;
}

.mobile-dashboard .dashboard-header .actions .action {
  width: 31px;
  height: 31px;
  filter: drop-shadow(0 3px 9px #00000014);
  background: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mobile-dashboard .dashboard-header h3 {
  margin-bottom: 24px;
  font-family: Gilroy-Bold;
  font-size: 18px;
  line-height: 27px;
}

.mobile-dashboard .items-list {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px 16px;
  margin-bottom: 24px;
  display: flex;
}

.mobile-dashboard .items-list .item {
  width: 47%;
  height: 186px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  row-gap: 10px;
  padding: 14px 6px;
  display: flex;
}

.mobile-dashboard .items-list .item:nth-of-type(1) {
  background-color: #c4dfff;
}

.mobile-dashboard .items-list .item:nth-of-type(2) {
  background-color: #e1f6f1;
}

.mobile-dashboard .items-list .item:nth-of-type(3) {
  background-color: #ffefca;
}

.mobile-dashboard .items-list .item:nth-of-type(4) {
  background-color: #ffd2e8;
}

@media screen and (min-width: 378px) {
  .mobile-dashboard .items-list .item {
    height: auto;
  }
}

.mobile-dashboard .items-list .item div {
  height: 76px;
  width: 76px;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mobile-dashboard .items-list .item h4 {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 24px;
}

.mobile-dashboard .pie-chart {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 22px;
  padding: 22px;
}

.mobile-dashboard .pie-chart h3 {
  margin-bottom: 10px;
  font-family: Gilroy-Bold;
  font-size: 16px;
  line-height: 24px;
}

.desktop-dashboard {
  display: none;
}

@media screen and (min-width: 1024px) {
  .desktop-dashboard {
    filter: drop-shadow(3.786px 2px 76px #00000014);
    background: #f4f5ff;
    border: 1px solid #463eff;
    border-radius: 20px;
    padding: 24px;
    display: block;
  }
}

.container {
  background: #fff;
  border-radius: 15px;
  grid-template-columns: 25% 75%;
  display: grid;
}

.container .col1 {
  background: #fdfff4;
  border-radius: 15px 0 0 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container .col1 div {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  column-gap: 17px;
  padding: 10px 26px;
  display: flex;
}

.container .col1 div.active {
  color: #fff;
  background: #79d7db;
  font-family: Gilroy-Bold;
  font-size: 18px;
  line-height: 27px;
}

.container .col1 div p:empty {
  height: 12px;
  background: #dfdfdf;
  border-radius: 5px;
}

.container .col1 div p:empty:nth-of-type(1) {
  width: 75px;
}

.container .col1 div p:empty:nth-of-type(2) {
  width: 65px;
}

.container .col1 div p:empty:nth-of-type(3) {
  width: 70px;
}

.container .col1 div p:empty:nth-of-type(4) {
  width: 40px;
}

.container .col1 div p:empty:nth-of-type(5) {
  width: 80px;
}

.container .col2 {
  width: 100%;
  padding: 41px 30px;
}

.container .dashboard-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.container .dashboard-header h3 {
  font-family: Gilroy-Bold;
  font-size: 23px;
  line-height: 34px;
}

.container .dashboard-header > div {
  justify-content: center;
  align-items: center;
  column-gap: 36px;
  display: flex;
}

.container .dashboard-header > div div {
  filter: drop-shadow(0 3px 9px #00000014);
  height: 38px;
  width: 38px;
  background-color: #fff;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container .cards-list {
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 37px;
  display: flex;
}

.container .cards-list .card-product {
  height: 180px;
  width: 174px;
  background: #79d7db;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 30px;
  padding: 24px 10px 10px;
  display: flex;
}

.container .cards-list .card-product:nth-of-type(1) {
  background: #c4dfff;
}

.container .cards-list .card-product:nth-of-type(2) {
  background: #e1f6f1;
}

.container .cards-list .card-product:nth-of-type(3) {
  background: #ffefca;
}

.container .cards-list .card-product:nth-of-type(4) {
  background: #ffd2e8;
}

.container .cards-list .card-product div {
  height: 51px;
  width: 51px;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container .cards-list .card-product div img {
  width: 26px;
  height: auto;
  object-fit: contain;
}

@media screen and (min-width: 1300px) {
  .container .statistics {
    column-gap: 37px;
    display: flex;
  }
}

.container .barchart {
  text-align: left;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 37px;
  padding: 20px 25px;
}

@media screen and (min-width: 1300px) {
  .container .barchart {
    width: 70%;
    height: 100%;
    margin-bottom: 0;
  }
}

.container .barchart img {
  width: 100%;
}

.container .piechart {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 20px 25px;
}

@media screen and (min-width: 1300px) {
  .container .piechart {
    width: 30%;
  }
}

.container .piechart h3 {
  margin-bottom: 40px;
}

#process {
  text-align: center;
  margin-bottom: 60px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  #process {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1024px) {
  #process {
    margin-top: 100px;
    padding: 0 92px;
  }
}

.process .section-heading {
  margin-bottom: 45px;
}

@media screen and (min-width: 768px) {
  .process .section-heading {
    margin-bottom: 88px;
  }
}

.process .section-heading h2 {
  padding: 0 16px;
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .process .section-heading h2 {
    font-size: 36px;
    line-height: 54px;
  }
}

.process .section-heading h2 span {
  color: #463eff;
}

.process .items-list {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 34px 0;
  margin-bottom: 40px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .process .items-list {
    background-image: url("bg2.00cce9b9.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 80%;
    flex-wrap: wrap;
    gap: 76px 300px;
    margin-bottom: 90px;
  }
}

.process .item {
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 9px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .process .item {
    width: max-content;
    row-gap: 28px;
  }
}

.process .item:nth-of-type(1) div {
  background-color: #f7e0ff;
}

.process .item:nth-of-type(2) div {
  background-color: #b9ebf7;
}

.process .item:nth-of-type(3) div {
  background-color: #ffefca;
}

.process .item:nth-of-type(4) div {
  background-color: #ddfad4;
}

.process .item:nth-of-type(5) div {
  background-color: #ffd4ce;
}

.process .item div {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .process .item div {
    height: 121px;
    width: 121px;
  }
}

.process .item div img {
  width: 71px;
  transition: all .5s linear;
  transform: translateY(-30px);
}

@media screen and (min-width: 1024px) {
  .process .item div img {
    width: 120px;
  }
}

.process .item div img:hover {
  transition: all .5s linear;
  transform: translateY(0);
}

.process .item h3 {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 768px) {
  .process .item h3 {
    font-size: 24px;
    line-height: 36px;
  }
}

.meeting {
  filter: drop-shadow(3.786px 2px 76px #00000014);
  background-color: #fff;
  border: 1px solid #463eff;
  border-radius: 24px;
  padding: 23px;
}

@media screen and (min-width: 600px) {
  .meeting {
    width: 70%;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .meeting {
    width: 100%;
    grid-template-columns: 48% 1% 48%;
    justify-content: center;
    align-items: center;
    margin: 0;
    display: grid;
  }
}

.meeting-form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (min-width: 600px) {
  .meeting-form {
    width: max-content;
    margin: 0 auto 32px;
  }
}

@media screen and (min-width: 768px) {
  .meeting-form {
    width: 90%;
    margin: auto;
    padding: 32px;
  }
}

.meeting-form h2 {
  margin-bottom: 37px;
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .meeting-form h2 {
    text-align: left;
  }
}

.meeting-form .field {
  text-align: left;
  margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .meeting-form .field {
    width: 100%;
  }
}

.meeting-form .field label {
  width: 100%;
  margin-bottom: 12.75px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

@media screen and (min-width: 768px) {
  .meeting-form .field label {
    font-size: 20px;
    line-height: 30px;
  }
}

.meeting-form .field input, .meeting-form .field textarea {
  width: 100%;
  border: 1px solid #000;
  border-radius: 12px;
  padding: 10px 20px;
  font-family: Gilroy;
  display: block;
}

.meeting-form .field input:active, .meeting-form .field input:focus, .meeting-form .field textarea:active, .meeting-form .field textarea:focus {
  outline-color: #443dfb;
}

@media screen and (min-width: 768px) {
  .meeting-form .field input, .meeting-form .field textarea {
    font-size: 18px;
  }
}

.meeting-form a, .meeting-form button {
  height: 40px;
  width: 180px;
  color: #fff;
  background: #443dfb;
  border: none;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-family: Gilroy-Bold;
  font-size: 14px;
  line-height: 21px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .meeting-form a, .meeting-form button {
    height: 64px;
    width: 249px;
    padding: 19px 44px;
    font-size: 20px;
    line-height: 30px;
  }
}

.meeting-form h3 {
  margin: 20px 0;
}

.meeting-form a {
  width: max-content;
  color: #000;
  background-color: #fff;
  border: 1px solid #443dfb;
}

.calendly-inline-widget {
  display: none;
}

@media screen and (min-width: 768px) {
  .calendly-inline-widget {
    display: block;
  }
}

.mobile-calendly {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .mobile-calendly {
    display: none;
  }
}

.book-meeting-link {
  height: 40px;
  width: 220px;
  color: #443dfb;
  border: 1px solid #443dfb;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin: 23px auto 0;
  padding: 10px;
  font-family: Gilroy-Bold;
  font-size: 14px;
  line-height: 21px;
  display: flex;
}

.border {
  display: none;
}

@media screen and (min-width: 768px) {
  .border {
    width: 2px;
    height: 100%;
    border-right: 1px solid #463eff;
    display: block;
  }
}

#client {
  text-align: center;
  margin-bottom: 60px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  #client {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1024px) {
  #client {
    margin-top: 100px;
    padding: 0 92px;
  }
}

.client {
  background-image: url("bg2.00cce9b9.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
}

.client .section-heading {
  margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .client .section-heading {
    margin-bottom: 50px;
  }
}

.client .section-heading p {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media screen and (min-width: 768px) {
  .client .section-heading p {
    margin-bottom: 3px;
    font-size: 18px;
    line-height: 27px;
  }
}

.client .section-heading h2 {
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .client .section-heading h2 {
    font-size: 36px;
    line-height: 54px;
  }
}

.client .section-heading h2 span {
  color: #463eff;
}

.clients-mobile {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 46px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .clients-mobile {
    display: none;
  }
}

.clients-desktop {
  display: none;
}

@media screen and (min-width: 768px) {
  .clients-desktop {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 106px;
    display: flex;
  }
}

.clients-desktop img {
  width: 100%;
  height: auto;
}

#services {
  text-align: center;
  margin-bottom: 60px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  #services {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1024px) {
  #services {
    margin-top: 100px;
    padding: 0 92px;
  }
}

.services .section-heading {
  margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .services .section-heading {
    margin-bottom: 40px;
  }

  .services .section-heading br {
    display: none;
  }
}

.services .section-heading p {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media screen and (min-width: 768px) {
  .services .section-heading p {
    margin-bottom: 3px;
    font-size: 18px;
    line-height: 27px;
  }
}

.services .section-heading h2 {
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .services .section-heading h2 {
    font-size: 36px;
    line-height: 54px;
  }
}

.services .section-heading h2 span {
  color: #463eff;
}

.services h3 {
  margin-bottom: 15px;
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 30px;
}

@media screen and (min-width: 768px) {
  .services h3 {
    text-align: left;
    font-size: 30px;
    line-height: 54px;
  }
}

.service-list {
  margin-bottom: 60px;
}

.service-list .owl-item {
  padding: 20px 30px 0;
}

.service-list .owl-carousel .owl-nav {
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  margin-top: 20px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .service-list .owl-carousel .owl-nav {
    margin-top: 60px;
  }
}

.service-list .owl-carousel .owl-nav button.owl-prev, .service-list .owl-carousel .owl-nav button.owl-next {
  height: 38px;
  width: 38px;
  color: #fff;
  background: #463eff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.service-list .owl-carousel .owl-nav button.owl-prev span, .service-list .owl-carousel .owl-nav button.owl-next span {
  font-size: 30px;
}

.service-list .owl-carousel .owl-nav button.owl-prev.disabled, .service-list .owl-carousel .owl-nav button.owl-next.disabled {
  color: #0006;
  background: none;
  border: 1px solid #0006;
}

.service-item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .service-item {
    row-gap: 32px;
  }
}

.service-item .pink {
  background: #f7e0ff;
}

.service-item .blue {
  background: #b9ebf7;
}

.service-item .orange {
  background: #ffefca;
}

.service-item div {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .service-item div {
    height: 121px;
    width: 121px;
  }
}

.service-item div:hover img {
  transition: all .5s linear;
  transform: translateY(0);
}

.service-item div img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: all .5s linear;
  transform: translateY(-20px);
}

.service-item h4 {
  font-family: Gilroy-Bold;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 1024px) {
  .service-item h4 {
    font-size: 24px;
    line-height: 36px;
  }
}

#values {
  text-align: center;
  margin-bottom: 60px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  #values {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1024px) {
  #values {
    padding: 0 92px;
  }
}

.values .section-heading {
  margin-bottom: 80px;
}

.values .section-heading h2 {
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .values .section-heading h2 {
    font-size: 36px;
    line-height: 54px;
  }
}

.values .section-heading h2 span {
  color: #463eff;
}

.values-list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 78px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .values-list {
    flex-direction: row;
    column-gap: 20px;
  }
}

.value-item {
  border-radius: 10px;
  padding: 0 25px 50px;
  position: relative;
}

.value-item:nth-of-type(1) {
  border: 2px solid #fd49fd;
}

.value-item:nth-of-type(1) div div {
  background: #ffe7f4;
}

.value-item:nth-of-type(2) {
  border: 2px solid #499cfd;
}

.value-item:nth-of-type(2) div div {
  background: #d5e8ff;
}

.value-item:nth-of-type(3) {
  border: 2px solid #f5b522;
}

.value-item:nth-of-type(3) div div {
  background: #fff5dc;
}

.value-item > div {
  width: 100%;
  height: max-content;
  justify-content: center;
  margin-top: -45px;
  margin-bottom: 38px;
  display: flex;
}

.value-item > div div {
  width: 98px;
  height: 98px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.value-item h4 {
  margin-bottom: 14.5px;
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 30px;
}

.value-item p {
  font-size: 12px;
  line-height: 18px;
}

#testimonials {
  text-align: center;
}

@media screen and (min-width: 1024px) {
  #testimonials {
    padding: 90px 0;
  }
}

.testimonials .section-heading {
  margin-bottom: 21px;
}

@media screen and (min-width: 768px) {
  .testimonials .section-heading {
    margin-bottom: 40px;
  }
}

.testimonials .section-heading p {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media screen and (min-width: 768px) {
  .testimonials .section-heading p {
    margin-bottom: 3px;
    font-size: 18px;
    line-height: 27px;
  }
}

.testimonials .section-heading h2 {
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .testimonials .section-heading h2 {
    font-size: 36px;
    line-height: 54px;
  }
}

.testimonials .section-heading h2 span {
  color: #463eff;
}

.testimonials .marquee-container {
  height: 200px;
  width: 100%;
  gap: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.testimonials .marquee, .testimonials .marquee-list {
  align-items: center;
  gap: 20px;
  display: flex;
}

.testimonials-item {
  width: 300px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding: 16px;
}

.testimonials-item.orange {
  background: #fffbeb;
}

.testimonials-item.pink {
  background: #fff4fa;
}

.testimonials-item.green {
  background: #effffb;
}

.testimonials-item p {
  margin-bottom: 63px;
  font-family: Gilroy-Medium;
  font-size: 12px;
  line-height: 18px;
}

.testimonials-item p img {
  vertical-align: top;
  width: 7px;
  height: 7px;
}

.testimonials-item .user {
  text-align: left;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.testimonials-item .user .avatar {
  width: 33px;
  height: 33px;
}

.testimonials-item .user .avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.testimonials-item .user .details {
  font-size: 12px;
  line-height: 18px;
}

.testimonials-item .user .details .name {
  font-family: Gilroy-Bold;
}

.testimonials-item .user .details .role {
  font-weight: 400;
}

.testimonials-item .user .rating img {
  width: 11px;
  height: 11px;
}

#consult {
  text-align: center;
  color: #fff;
  background: #463eff;
  padding: 40px;
}

@media screen and (min-width: 768px) {
  #consult {
    padding: 92px;
  }
}

.consult .section-heading {
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .consult .section-heading {
    margin-bottom: 60px;
  }
}

.consult .section-heading h2 {
  font-family: Gilroy-Bold;
  font-size: 24px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .consult .section-heading h2 {
    font-size: 36px;
    line-height: 54px;
  }
}

.consult .meeting-btn {
  justify-content: center;
  display: flex;
}

.consult .meeting-btn a {
  height: 64px;
  width: 249px;
  color: #000;
  background-color: #fff;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 30px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .consult .meeting-btn a {
    height: 64px;
    width: 249px;
    padding: 19px 44px;
    font-size: 20px;
    line-height: 30px;
  }
}

.consult-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 46px;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .consult-list {
    column-gap: 200px;
    margin-bottom: 60px;
  }
}

.consult-item {
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  display: flex;
}

.consult-item:nth-of-type(1) div {
  background-color: #f7e0ff;
}

.consult-item:nth-of-type(2) div {
  background-color: #b9ebf7;
}

.consult-item:nth-of-type(3) div {
  background-color: #ffefca;
}

.consult-item div {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .consult-item div {
    height: 121px;
    width: 121px;
  }
}

.consult-item div:hover img {
  transition: all .5s linear;
  transform: translateY(0);
}

.consult-item div img {
  width: auto;
  height: 77px;
  object-fit: contain;
  transition: all .5s linear;
  transform: translateY(-20px);
}

@media screen and (min-width: 1024px) {
  .consult-item div img {
    height: 100px;
  }
}

.consult-item h3 {
  font-family: Gilroy-Bold;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 768px) {
  .consult-item h3 {
    font-size: 24px;
    line-height: 36px;
  }
}

footer {
  color: #fff;
  text-align: center;
  background-color: #000;
}

.footer-nav {
  padding: 35px;
}

@media screen and (min-width: 1024px) {
  .footer-nav {
    text-align: left;
    justify-content: space-between;
    align-items: flex-start;
    padding: 106px 92px;
    display: flex;
  }
}

.footer-nav > div {
  margin-bottom: 32px;
}

@media screen and (min-width: 1024px) {
  .footer-nav > div {
    width: 25%;
  }
}

.footer-nav h2 {
  margin-bottom: 16px;
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 30px;
}

.footer-nav p, .footer-nav a, .footer-nav address {
  color: #b7b7b7;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.footer-nav li {
  margin-bottom: 12px;
}

.footer-nav > div > div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 11px;
  margin-bottom: 28px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .footer-nav > div > div {
    grid-template-columns: 10% 70%;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 21px;
    display: grid;
  }
}

.footer-nav > div > div.socials-list {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 19px;
  display: none;
}

@media screen and (min-width: 1024px) {
  .footer-nav > div > div.socials-list {
    display: flex;
  }
}

.footer-nav .socials {
  justify-content: center;
  align-items: center;
  column-gap: 19px;
  margin: 0;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .footer-nav .socials {
    display: none;
  }
}

.copyright {
  text-align: center;
  color: #b7b7b7;
  background: #242323;
  padding: 9px 0;
  font-family: Gilroy-Medium;
  font-size: 8px;
  line-height: 12px;
}

@media screen and (min-width: 768px) {
  .copyright {
    padding: 18px 0;
    font-size: 16px;
    line-height: 24px;
  }
}

/*# sourceMappingURL=index.0aaa88a4.css.map */
